<template>
  <!--
    Enables upload of theme files to the amazon cloud for terminal
  -->
  <div class="themeStaticResourceUploader">
    <Portlet
      :title="$t('staticResourceUploaderComponent.themeManagement')"
      icon="upload"
    >
      <LoadingPlaceholder v-if="loading" />

      <div
        v-else
        class="row"
      >
        <div class="col-12 col-xl-3 mb-3">
          <Portlet
            :title="$t('staticResourceUploaderComponent.upload')"
            icon="upload"
          >
            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.files') }}</label>
              </div>
              <input
                ref="fileInput"
                type="file"
                multiple
                class="form-control cursor-pointer rounded"
                @change="getFilesFromInput($event)"
              >
            </div>
            
            <div
              v-if="uploadPercentage > 0"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
              </div>
              <div class="progress">
                <div
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${ uploadPercentage }%;`"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercentage }} %
                </div>
              </div>
            </div>
            
            <template v-if="uploadPercentage == 0">
              <button
                v-if="themeFiles != null && themeFiles.length > 0"
                class="btn btn-primary btn-block"
                @click="saveFile()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
              <button
                v-else
                class="btn btn-primary btn-block cursor-not-allowed"
                disabled
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
            </template>
          </Portlet>
        </div>
        <div class="col-12 col-xl-9">
          <Portlet
            :title="$t('staticResourceUploaderComponent.files')"
            icon="image"
          >
            <Grid
              v-if="allFiles"
              :ref="kgm_ref"
              :style="{height: 'auto'}"
              :data-items="kgm_computedGridItems(allFiles)"
              :columns="kgm_responsiveColumns()"
              :filterable="true"
              :filter="kgm_filter"
              :pageable="kgm_pagable"
              :page-size="kgm_take"
              :skip="kgm_skip"
              :take="kgm_take"
              :total="kgm_allGridItems(allFiles)"
              :sortable="{
                allowUnsort: kgm_allowUnsort,
                mode: kgm_sortMode
              }"
              :sort="kgm_sort"
              selected-field="selected"
              @rowclick="kgm_gridOnRowClick"
              @filterchange="kgm_gridFilterChange"
              @pagechange="kgm_gridPageChange"
              @sortchange="kgm_gridSortChange"
            >
              <template
                slot="optionsTemplate"
                slot-scope="{props}"
              >
                <td :class="props.className">
                  <div class="btn-group">
                    <button
                      class="btn btn-secondary btn-sm"
                      @click="openConfirmModal(props.dataItem.name)"
                    >
                      <font-awesome-icon
                        class="mr-2 gray"
                        icon="trash"
                      />
                      <span>Remove</span>
                    </button>
                  </div>
                </td>
              </template>
              <template
                slot="sizeTemplate"
                slot-scope="{props}"
              >
                <td
                  v-tooltip="`${ props.dataItem.size } bytes`"
                  :class="props.className"
                >
                  {{ numberConversionAdapter_bytes(props.dataItem.size).bytes }} {{ numberConversionAdapter_bytes(props.dataItem.size).unit }}
                </td>
              </template>
              <template
                slot="dateTemplate"
                slot-scope="{props}"
              >
                <td
                  v-tooltip="dateTime_fromNow(props.dataItem.lastChangeDate)"
                  :class="props.className"
                >
                  {{ dateTime_dateTime(props.dataItem.lastChangeDate) }}
                </td>
              </template>
              <template
                slot="pathTemplate"
                slot-scope="{props}"
              >
                <td
                  :class="props.className"
                >
                  <a
                    :href="`https://skiline.s3-eu-west-1.amazonaws.com/${ props.dataItem.path }`"
                    target="_blank"
                    class="cursor-pointer d-inline-block"
                    style="color: #1f9fd9;"
                  >{{ props.dataItem.path }}</a>
                </td>
              </template>
              <div
                slot="nameFilter"
                slot-scope="{props, methods}"
                class="input-group"
              >
                <input
                  ref="nameFilterInput"
                  :value="props.value"
                  type="text"
                  class="form-control mr-2 rounded-right"
                  @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
                >
                <div
                  v-if="props.value"
                  class="input-group-append"
                >
                  <button
                    class="btn btn-light border rounded"
                    @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                  >
                    <font-awesome-icon
                      class="gray"
                      icon="times"
                    />
                  </button>
                </div>
              </div>
            </Grid>
            <p v-else>
              {{ $t('noDataAvailable') }}
            </p>
          </Portlet>
        </div>
      </div>
    </Portlet>
    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeFile(deleteFileName)"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "StaticResourceUploader",
  components: {
    SweetModal,
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    numberConversionAdapter
  ],
  data () {
    return {
      loading: true,
      themeFiles: [],
      allFiles: null,
      deleteFileName: null,
      uploadPercentage: 0,
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          filterCell: 'nameFilter',
          title: this.$t('name'),
        },
        {
          field: 'size',
          filterable: false,
          filter: 'text',
          title: this.$t('size'),
          cell: 'sizeTemplate',
          hideOn: ['smDown']
        },
        {
          field: 'lastChangeDate',
          filterable: false,
          filter: 'text',
          title: this.$t('date'),
          cell: 'dateTemplate',
          hideOn: ['smDown']
        },
        {
          field: 'path',
          filterable: false,
          filter: 'text',
          title: this.$t('path'),
          cell: 'pathTemplate',
          hideOn: ['mdDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  created () {
    this.getThemes();
  },
  methods: {
    openConfirmModal (fileName) {
      this.deleteFileName = fileName;
      this.$refs.confirmDelete.open();
    },
    getFilesFromInput () {
      this.themeFiles = this.$refs.fileInput.files;
    },
    saveFile () {
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      
      Object.keys(this.themeFiles).forEach((i) => {
        bodyFormData.append("formFiles", this.themeFiles[i]);
      });

      this.axios.post(`/StaticResource/UploadTerminalThemes`,
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'), this.$t('staticResourceUploaderComponent.fileSavedTitle'));
        }
      })
      .finally(() => {
        this.getThemes();
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });
    },
    getThemes () {
      this.loading = true;
      this.axios.get(`/StaticResource/GetTerminalThemeFiles`)
      .then((response) => {
        if (response != null && response.data != null) {
          this.allFiles = response.data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeFile (imageName) {
      this.axios.delete(`/StaticResource/DeleteTerminalThemeFile/${ imageName }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileDeletedMessage'), this.$t('staticResourceUploaderComponent.fileDeletedTitle'));
          }
        })
        .finally(() => {
          this.$refs.confirmDelete.close();
          this.getThemes();
      });
    }
  }
}
</script>

<style>
.themeStaticResourceUploader input[type="file"] {
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
}
.themeStaticResourceUploader .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.themeStaticResourceUploader .placeholderIcon {
  font-size: 2rem;
}
.themeStaticResourceUploader .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
